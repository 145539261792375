import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
export const SSImg1 = require("../../assets/images/blogs/successstories/ss1.webp")
export const SSImg2 = require("../../assets/images/blogs/successstories/ss2.webp")
export const SSImg3 = require("../../assets/images/blogs/successstories/ss3.webp")
export const SSImg4 = require("../../assets/images/blogs/successstories/ss4.webp")
export const SSImg5 = require("../../assets/images/blogs/successstories/ss5.webp")
export const SSImg6 = require("../../assets/images/blogs/successstories/ss6.webp")
export const SSImg7 = require("../../assets/images/blogs/successstories/ss7.webp")
export const SSImg8 = require("../../assets/images/blogs/successstories/ss8.webp")
export const SSImg9 = require("../../assets/images/blogs/successstories/ss9.webp")
export const SSImg10 = require("../../assets/images/blogs/successstories/ss10.webp")
export const SSImg11 = require("../../assets/images/blogs/successstories/Voosh Powers Farm Burger.webp")
export const SSImg12 = require("../../assets/images/blogs/successstories/Voosh Powers Groucho's Deli's Growth.webp")
export const SSImg13 = require("../../assets/images/blogs/successstories/knocking out unjust.webp")
export const SSImg14 = require('../../assets/images/blogs/successstories/ss12.webp')
export const SSImg15 = require('../../assets/images/blogs/successstories/ss13.webp')
export const SSImg16 = require('../../assets/images/blogs/successstories/How a Top Bay Area Restaurant Chain Saved Over $500k in a Year.webp')
export const SSImg17 = require('../../assets/images/blogs/successstories/how Voosh transformed their operations and saved them thousands.webp')
export const SSImg18 = require('../../assets/images/blogs/successstories/Why would you NOT automate it.webp')

const SSDataJson = [
    {
        "id" : 1,
        "img" : SSImg1,
        "title" : "Plnt Burger Thrives in the Online Marketplace Thanks to Voosh.",
        "t1" : "-33.13%",
        "t2" : "Error charges in 2 weeks",
        "t3" : "49.18%",
        "t4" : "Win rate in disputes",
        "publishedDate" : "25 December 2023",
        "url" : "/success-stories/plnt-burger",
        "desc" : "Plnt Burger Thrives in the Online Marketplace Thanks to Voosh"
    },
    {
        "id" : 2,
        "img" : SSImg2,
        "title" : "FarmBurger's Remarkable Journey with Voosh",
        "t1" : "69.51%",
        "t2" : "Dispute win rate in 15 days",
        "t3" : "-6.81%",
        "t4" : "Error charges within 15 days",
        "publishedDate" : "25 December 2023",
        "url" : "/success-stories/farm-burger",
        "desc" : "FarmBurger's Remarkable Journey with Voosh"
    },
    {
        "id" : 3,
        "img" : SSImg3,
        "title" : "Profitable Promotions: A New York Chain's Recipe for 19.92x ROI",
        "t1" : "19.92x",
        "t2" : "$88k spend into $1.75M",
        "t3" : "8.3x",
        "t4" : "sales over $1 million",
        "publishedDate" : "24 November 2023",
        "url" : "/success-stories/profitable-promotions-a-%20new-york-chain-recipe-for-19.92x-ROI",
        "desc" : "Discover how a New York culinary chain revolutionized their profits with savvy promotions, turning a modest investment in DoorDash, UberEats & GrubHub into a stunning 19.92x ROI. Uncover their success secrets and get inspired for your own promotional strategies."
    },
    {
        "id" : 4,
        "img" : SSImg4,
        "title" : "🍕 &pizza + Voosh: A Match Made in Pizza Heaven 🍕",
        "t1" : "$10,000+",
        "t2" : "Recovered in disputes!",
        "t3" : "96%",
        "t4" : "Dispute Win Rate!",
        "publishedDate" : "31 December 2023",
        "url" : "/success-stories/&pizza+Voosh-a-match-made-in-pizza-heaven",
        "desc" : "Explore the success story of &pizza and Voosh, where effective dispute management led to significant financial gains. In just 10 days, &pizza recovered $10,000+ and achieved a 96% dispute win rate. This case study showcases how Voosh's Dispute Manager benefited &pizza’s 55 locations. Learn more and book a free demo to see how Voosh can help your business."
    },
    {
        "id" : 5,
        "img" : SSImg5,
        "title" : "🤯 Did you know a global food giant is projected to save $340,000 in just 30 days?",
        "t1" : "$340,000",
        "t2" : "Projected savings in 30 days",
        "t3" : "20x",
        "t4" : "ROI per store",
        "publishedDate" : "26 January 2024",
        "url" : "/success-stories/did-you-know-a-global-food-giant-is-projected-to-save-$340,000-in-just-30-days",
        "desc" : "Explore the success story of Joe & The Juice and Voosh, where effective dispute management led to significant financial gains. In just 10 days, Joe & The Juice recovered $7,500+ and saved 60 hours while appealing disputes with Voosh. This case study showcases how Voosh's Dispute Manager benefited Joe & The Juice’s 20 Pilot locations. Learn more and book a free demo to see how Voosh can help your business."
    },
    {
        "id" : 6,
        "img" : SSImg6,
        "title" : "⚡ Breaking News: Voosh Achieves Significant Financial Recovery for DIG! 💰",
        "t1" : "4,000",
        "t2" : "Disputed orders appealed successfully",
        "t3" : "300 Hrs",
        "t4" : "work saved",
        "publishedDate" : "10 March 2024",
        "url" : "/success-stories/breaking-news-voosh-achieves-significant-financial-recovery-for-dig",
        "desc" : "Discover how Voosh revolutionized DIG's finances, resolving 4,000 disputed orders & saving 300 hours. Schedule a free demo!"
    },
    {
        "id" : 7,
        "img" : SSImg7,
        "title" : "🔺Voosh Saves Original ChopShop a Whopping $27,000! 🔥",
        "t1" : "3,300",
        "t2" : "Disputed orders appealed successfully",
        "t3" : "$27,000+",
        "t4" : "Recovered",
        "publishedDate" : "22 March 2024",
        "url" : "/success-stories/voosh-saves-original-chop-shop-a-whopping-$27,000",
        "desc" : "Discover how Voosh revolutionized Original ChopShop's profits, recovering $27,000+ by resolving 3,300+ disputed orders & saving 300 hours. Schedule a free demo!"
    },
    {
        "id" : 8,
        "img" : SSImg8,
        "title" : "Voosh Among Top 3 Startups at MURTEC 2024! 🚀",
        "t1" : "$400k",
        "t2" : "Saved in about 8 months",
        "t3" : "Reduced",
        "t4" : "Manual works.",
        "publishedDate" : "25 March 2024",
        "url" : "/success-stories/voosh-among-top-3-startups-at-murtec-2024",
        "desc" : "Voosh has been named one of the top three startups of 2024 at the Restaurant Technology Network's multi-unit restaurant technology conference(MURTEC) in Las Vegas!"
    },
    {
        "id" : 9,
        "img" : SSImg9,
        "title" : "Voosh.ai powered 2 million+ orders, hitting $57M in sales! 🚀",
        "t1" : "80%",
        "t2" : "Disputed orders recovered",
        "t3" : "$400K",
        "t4" : "Revenue",
        "publishedDate" : "25 March 2024",
        "url" : "/success-stories/voosh-ai-powered-2-million-plus-orders-hitting-$57M-in-sales",
        "desc" : "In just the past 8 months, Voosh.ai powered 2 million+ orders, hitting $57M in sales. But 50K+ orders are disputed, risking over $500K of your hard-earned revenue!"
    },
    {
        "id" : 10,
        "img" : SSImg10,
        "title" : "🔥 Essential Dispute Resolution Strategies for Restaurants 🔥",
        "t1" : "$25,000",
        "t2" : "Weekly earnings",
        "t3" : "Proven",
        "t4" : "Dispute resolution",
        "publishedDate" : "27 March 2024",
        "url" : "/success-stories/essential-dispute-resolution-strategies-for-restaurants",
        "desc" : "Boost your restaurant's growth with proven dispute resolution strategies! Discover how automation, data analysis, and training with Voosh.ai can increase your weekly earnings from $3,000 to $25,000. Start now!"
    },
    {
        "id" : 11,
        "img" : SSImg11,
        "title" : "🔺Voosh Saves Farm Burger a Whopping $7,000! 🔥",
        "t1" : "1,500",
        "t2" : "Disputed orders appealed successfully",
        "t3" : "$7,000+",
        "t4" : "Recovered",
        "publishedDate" : "28 March 2024",
        "url" : "/success-stories/voosh-saves-farm-burger-a-whopping-$7,000",
        "desc" : "Discover how Voosh revolutionized Farm Burger's profits, recovering $7,000+ by resolving 1,500+ disputed orders & saving over 100 hours. Schedule a free demo!"
    },
    {
        "id" : 12,
        "img" : SSImg12,
        "title" : "Voosh Saves Groucho's Deli a Whopping $13,000! 🔥",
        "t1" : "1,700",
        "t2" : "Disputed orders appealed successfully",
        "t3" : "$13,000+",
        "t4" : "Recovered",
        "publishedDate" : "02 April 2024",
        "url" : "/success-stories/voosh-saves-grouchos-deli-a-whopping-$13,000",
        "desc" : "Discover how Voosh revolutionized Groucho's Deli's profits, recovering $13,000+ by resolving 1,700+ disputed orders & saving over 130 hours. Schedule a free demo!"
    },
    {
        "id" : 13,
        "img" : SSImg13,
        "title" : "🚀 Here's How a Northeast Restaurant Chain Recovered $72,000 in 7 Months! 🚀",
        "t1" : "2.13%",
        "t2" : "Disputed orders appealed successfully",
        "t3" : "$72,000+",
        "t4" : "Recovered",
        "publishedDate" : "07 Jun 2024",
        "url" : "/success-stories/here-how-a-northeast-restaurant-chain-recovered-72000-in-7-months",
        "desc" : "Discover how Voosh revolutionized profits, recovering $72,000+ by resolving disputed orders & saving over thousands of hours. Schedule a free demo!"
    },
    {
        "id" : 14,
        "img" : SSImg14,
        "title" : "How a Health-Focused Eatery Recovered $35,000 in 8 Months! 🚀",
        "t1" : "na",
        "t2" : "na",
        "t3" : "$35,000+",
        "t4" : "Recovered in disputes!",
        "publishedDate" : "14 Jun 2024",
        "url" : "/success-stories/how-a-health-focused-eatery-recovered-35000-dollars-in-8-months",
        "desc" : "Dominate the food delivery market with Voosh's Dispute Management. Optimize operations, enhance customer satisfaction, and boost profits."
    },
    {
        "id" : 15,
        "img" : SSImg15,
        "title" : "🔥 Voosh has powered an incredible $100 MILLION in sales with 5 MILLION+ orders! 🔥",
        "t1" : "$100 million",
        "t2" : "in sales",
        "t3" : "5 million+",
        "t4" : "Orders",
        "publishedDate" : "14 Jun 2024",
        "url" : "/success-stories/voosh-has-powered-an-incredible-100-million-dollar-in-sales-with-5-million-plus-orders",
        "desc" : "Voosh has helped restaurants recover significant amounts in disputed orders using Voosh's Dispute Manager"
    },
    {
        "id" : 16,
        "img" : SSImg16,
        "title" : "💥 How a Top Bay Area Restaurant Chain Saved Over $500k in a Year 🤯",
        "t1" : "na",
        "t2" : "na",
        "t3" : "$500k+",
        "t4" : "Saved in a Year",
        "publishedDate" : "25 Jun 2024",
        "url" : "/success-stories/how-a-top-bay-area-restaurant-chain-saved-over-500k-dollars-in-a-year",
        "desc" : "How a Top Bay Area Restaurant Chain Saved Over $500k in a Year using Voosh's Downtime Controller"
    },
    {
        "id" : 17,
        "img" : SSImg17,
        "title" : "Christopher Treloar, CEO of PLNT Burger, shares how Voosh transformed their operations and saved them thousands! 🏆",
        "t1" : "na",
        "t2" : "na",
        "t3" : "Christopher Treloar",
        "t4" : "reveals how Voosh saved them thousands",
        "publishedDate" : "20 Jul 2024",
        "url" : "/success-stories/christopher-treloar-ceo-of-plnt-burger-shares-how-voosh-transformed-their-operations-and-saved-them-thousands",
        "desc" : "Discover how Voosh revolutionized PLNT Burger's operations! CEO Christopher Treloar reveals how Voosh's revenue recapture and advanced dashboard saved them thousands and boosted sales. Learn more about optimizing performance and climbing third-party app rankings with Voosh."
    }
    ,
    {
        "id" : 18,
        "img" : SSImg18,
        "title" : "🚨 Why would you NOT automate it?",
        "t1" : "na",
        "t2" : "na",
        "t3" : "Deric Rosenbaum, President of Groucho's Deli",
        "t4" : "Shares about Voosh's automated dispute resolution.",
        "publishedDate" : "13 Aug 2024",
        "url" : "/success-stories/why-would-you-not-automate-it",
        "desc" : "Hear from Deric Rosenbaum, President of Groucho's Deli , as he shares how Voosh's automated dispute resolution has been a game-changer for his team, saving time and money while turning third-party platforms into a significant revenue stream."
    }
]

export const SSArrays = [...SSDataJson].sort((a, b) => b.id - a.id)

const getRandomArrays = (data, numArrays) => {
    const shuffledData = [...data].sort(() => 0.5 - Math.random()); // Shuffle the data
    return shuffledData.slice(0, numArrays); // Select the specified number of arrays
  };

export const GetSimilarSSComponent = (props) => {
    const [randomArrays, setRandomArrays] = useState([]);
    // Generate four random arrays on initial render
    useEffect(() => {
        const arrays = getRandomArrays(SSDataJson, 4);
        setRandomArrays(arrays);
    }, [props.ssid]);
  
    return (
      <>
        <div className='catch-up-blog'>
            <p className='rtss1 mb-0'><strong>Catch up on other editions
                <svg className='svg-icon ms-1' style={{width: "1.25em", height: "1.25em",verticalAlign: "middle",fill: "#f36513",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M986.584615 602.584615a23.630769 23.630769 0 0 1-39.384615 15.753847L840.861538 512 596.676923 720.738462a66.953846 66.953846 0 0 1-96.492308 0l-147.692307-167.384616L90.584615 783.753846a21.661538 21.661538 0 0 1-33.476923 0l-11.815384-11.815384a21.661538 21.661538 0 0 1 0-31.507693l259.938461-352.492307a66.953846 66.953846 0 0 1 96.492308 0l147.692308 147.692307 149.661538-173.292307-96.492308-88.615385a25.6 25.6 0 0 1 15.753847-41.353846h322.953846a47.261538 47.261538 0 0 1 43.323077 49.230769z"  /></svg>    
            </strong></p>
            <div className="s2-dm-bdr" style={{backgroundColor:"#f36513"}}></div>
            {(randomArrays.filter(x => x.id !== Number(props.ssid))).map(item => (
                <Link key={item.id} to={item.url}>
                    <div className='row rounded py-2 mb-2'>
                        <div className='col-9'>
                            <p className='rtss2 mt-0'><strong>{item.title}</strong></p>
                            <p className='s-t2 text-black'>Posted on : {item.publishedDate}</p>
                        </div>
                        <div className='col-3 text-center'>
                            <img src={item.img} className='shadow-sm' alt='catch up blogs thumbnail' />
                        </div>
                    </div>
                </Link>
            ))}
            <div className='text-center'>
                <Link to={"/success-stories"}>
                    <div className='btn btn-outline-primary btn-with-arrow mt-4'><font className="pe-2">See all editions</font>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                            <path className='stroke-blue' d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </Link>
            </div>
        </div>
      </>
    );
};