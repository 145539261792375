// import { logGoogleEvent } from "../../firebase";
import Ubereats from '../../assets/images/UberEats.webp'
import Grubhub from '../../assets/images/Grubhub.webp'
import Doordash from '../../assets/images/Doordash.webp'
import Yelp from '../../assets/images/Yelp.webp'
import Google from '../../assets/images/Google.webp'
import Ezcater from '../../assets/images/Ezcater.webp'
import SkipTheDishes from '../../assets/images/skip-the-dishes.webp'
import { Alert, Badge } from 'react-bootstrap'


export const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
}

export const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

function Section1() {

  return (
    <div className="s1-container" id="vooshHome">
      <div className="f-width m-auto text-center">
        <div className="row">
          <Alert variant='light' className='top-alert-home' >
            🚀 Meet Us at FSTEC 2024! Booth <Badge bg='primary'>#830</Badge>
          </Alert>
          <div className="float-imgs animate__animated animate__lightSpeedInLeft">
            <img src={Doordash} alt="Doordash" />
            <img src={Ubereats} alt="UberEats" />
            <img src={Grubhub} alt="Grubhub" />
            <img src={SkipTheDishes} alt='Skip the dishes' />
            <img src={Ezcater} alt="Ezcater" />
            <img src={Google} alt="Google" />
            <img src={Yelp} alt="Yelp" />
            
          </div>
          <div className="col hero-data">
            <h1 className="hero-primary-text">
            Third-Party Dispute Resolution, Promotions, Finance, Downtime, and Reviews - <br/><span className='fw-bold text-white'>AUTOMATED FOR YOU!! </span>
            </h1>
            <p className="hero-secondary-text">
              {' '}Instantly recover chargebacks, create and manage promotions with AI-driven insights, and respond to customer reviews across all platforms—either using AI or manually. Keep your restaurant online with real-time downtime control. Gain clear insights into payout discrepancies, reconcile POS data with off-premise orders, and access detailed transaction insights—all in one place, across platforms like DoorDash, UberEats, GrubHub, SkipTheDishes, ezCater, DoorDash-Drive, Google, Yelp, and more.{' '}
            </p>
              <a className="btn btn-primary" href="https://meetings.hubspot.com/christopher136/learnmoreaboutvoosh" rel="noreferrer" target="_blank">
                <p className="btn-text d-inline">Book Demo</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                  <path d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            {/* <p className="n-cc-r n-cc-r2">No credit card required</p> */}
          </div>
        </div>
      </div>
      {/* <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <img src={Logo} width="150" alt="Voosh logo" />
          <p className={showData ? "m-header" : "d-none"}>Start my <font className="color-brand">free trial</font></p>
          <div className="m-auto">
            <HubspotForm portalId="23359971" formId="44c22f95-5c45-45f7-8800-531bf9eb8156" onSubmit={() => {
                    logGoogleEvent("Hubspot Form Submitted", {
                      name: "Hubspot Form Submitted",
                    });
                    setShowData(false);
                  }
                  } loading={<div>Loading...</div>} />
            <p className="n-cc-r text-secondary pt-0">No credit card required</p>
          </div>
        </div>
      </Modal.Body>
    </Modal> */}
    
    </div>
  )
}

export default Section1
