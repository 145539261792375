import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Card from 'react-bootstrap/Card';
import BtnArrow from '../../assets/images/right-arrow.svg'
import { logGoogleEvent } from '../../firebase'
import { SSArrays } from '../SucessStories/SSData'

const Section5 = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'center',
    centerMode: false,
    centerPadding: '0px',
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const arrayIdToFilter = [6,2,5,4,18,17,16]
  // Filter the array
  const filteredData = SSArrays.filter(item => arrayIdToFilter.includes(item.id));

  return (
    <div className="s5-container">
      <div className="f-width m-auto">
      <div className='p-s-head'>
          <p className='section-head mb-0'>Voosh Verified: Case Studies</p>
          <Link to={'/success-stories'}  onClick={() => {
              logGoogleEvent("redirect to /Case Studies page", {
                name: "redirect to /Case Studies page",
              });
            }}>
              <div className="btn btn-outline-primary btn-with-arrow v-all-btn">
              View all <img src={BtnArrow} className="ps-2" alt="Right Arrow" />{' '}
              </div>
          </Link>
      </div>
        
        <p className="section-subhead">From Ghost Kitchens to Multi-Unit Restaurants: Hear How Voosh is Turning 3rd Party Marketplace Data into Dollars.</p>
        <div className="row">
          <Slider {...settings}>
            {filteredData.map(item => (
              <div key={item.id} className='col-lg-4 col-md-6 col-sm-12'>
                <Card className='blog-card'>
                    <Card.Img variant="top" src={item.img} alt='Voosh Blogs thumbnail'/>
                    <Card.Body className='align-content-center'>
                    <Card.Title>{item.title}</Card.Title>
                    <div className='row ps-2'>
                        <div className={item.t1 === 'na' ? 'd-none' : 'col ss-card-b1 me-2'}>
                            <p className='t1 mb-0'>{item.t1}</p>
                            <p className='t2 mb-0'>{item.t2}</p>
                        </div>
                        <div className='col ss-card-b1 me-2'>
                            <p className='t1 mb-0'>{item.t3}</p>
                            <p className='t2 mb-0'>{item.t4}</p>
                        </div>
                    </div>
                    <Link to={item.url}>
                        <div className='btn btn-outline-primary btn-with-arrow mt-3'><font className="pe-2">Read this story</font>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                                <path className='stroke-blue' d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </Link>
                    </Card.Body>
                </Card>
              </div>
            ))}
          {/* <div className="col-lg-6 col-sm-12">
              <div className="card">
                <img src={Card5} className="card-img-top" alt="Dispute Resolution for Restaurants" />
                <div className="card-body ps-4">
                  <p className="card-title">⚡ Breaking News: Voosh Achieves Significant Financial Recovery for DIG! 💰</p>
                  <div className="container mb-3">
                    <div className="row justify-content-start">
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">4,000+</p>
                          <p className="t2">Disputed orders appealed</p>
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">300 Hrs</p>
                          <p className="t2">work saved</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={'/success-stories/breaking-news-voosh-achieves-significant-financial-recovery-for-dig'}  onClick={() => {
                    logGoogleEvent("Slider section[Home page] farm burger", {
                      name: "Slider section[Home page] farm burger",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                      View Details <img src={BtnArrow} className="ps-3" alt="Right Arrow" />{' '}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="card">
                <img src={Card4} className="card-img-top" alt="Dispute Resolution for Restaurants" />
                <div className="card-body ps-4">
                  <p className="card-title">🍕 &pizza + Voosh: A Match Made in Pizza Heaven 🍕</p>
                  <div className="container mb-3">
                    <div className="row justify-content-start">
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">$10,000+</p>
                          <p className="t2">Recovered in disputes!</p>
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">96%</p>
                          <p className="t2">Dispute Win Rate!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={'/success-stories/&pizza+Voosh-a-match-made-in-pizza-heaven'}  onClick={() => {
                    logGoogleEvent("Slider section[Home page] farm burger", {
                      name: "Slider section[Home page] farm burger",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                      View Details <img src={BtnArrow} className="ps-3" alt="Right Arrow" />{' '}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="card">
                <img src={Card3} className="card-img-top" alt="From Ghost Kitchens to Multi-Unit Restaurants" />
                <div className="card-body ps-4">
                  <p className="card-title">Did you know a global food giant is projected to save $340,000 in just 30 days?</p>
                  <div className="container mb-3">
                    <div className="row justify-content-start">
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">$340,000</p>
                          <p className="t2">Projected savings in 30 days</p>
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">20x</p>
                          <p className="t2">ROI per store</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={'/success-stories/did-you-know-a-global-food-giant-is-projected-to-save-$340,000-in-just-30-days'} onClick={() => {
                    logGoogleEvent("Slider section[Home page] plnt burger", {
                      name: "Slider section[Home page] plnt burger",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                      View Details <img src={BtnArrow} className="ps-3" alt="Right Arrow" />{' '}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="card">
                <img src={Card2} className="card-img-top" alt="From Ghost Kitchens to Multi-Unit Restaurants" />
                <div className="card-body ps-4">
                  <p className="card-title">Profitable Promotions: A New York Chain's Recipe for 19.92x ROI</p>
                  <div className="container mb-3">
                    <div className="row justify-content-start">
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">19.92x</p>
                          <p className="t2">$88k spend into $1.75M</p>
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">8.3x</p>
                          <p className="t2">sales over $1 million</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={'/success-stories/profitable-promotions-a- new-york-chain-recipe-for-19.92x-ROI'} onClick={() => {
                    logGoogleEvent("Slider section[Home page] profitable-promotions", {
                      name: "Slider section[Home page] profitable-promotions",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                      View Details <img src={BtnArrow} className="ps-3" alt="Right Arrow" />{' '}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="card">
                <img src={Card1} className="card-img-top" alt="From Ghost Kitchens to Multi-Unit Restaurants" />
                <div className="card-body ps-4">
                  <p className="card-title">FarmBurger's Remarkable Journey with Voosh - Enhanced Operational..</p>
                  <div className="container mb-3">
                    <div className="row justify-content-start">
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">69.51%</p>
                          <p className="t2">Dispute win rate in 15 days</p>
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="card-text">
                          <p className="t1">-6.81%</p>
                          <p className="t2">Error charges in 15 days</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={'/success-stories/farm-burger'}  onClick={() => {
                    logGoogleEvent("Slider section[Home page] farm burger", {
                      name: "Slider section[Home page] farm burger",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                      View Details <img src={BtnArrow} className="ps-3" alt="Right Arrow" />{' '}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
             */}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Section5
